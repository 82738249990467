// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-event-event-js": () => import("/builds/frekvens/frekvensapp.com/src/templates/event/event.js" /* webpackChunkName: "component---src-templates-event-event-js" */),
  "component---src-pages-404-js": () => import("/builds/frekvens/frekvensapp.com/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/builds/frekvens/frekvensapp.com/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-js": () => import("/builds/frekvens/frekvensapp.com/src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-search-js": () => import("/builds/frekvens/frekvensapp.com/src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/builds/frekvens/frekvensapp.com/.cache/data.json")

