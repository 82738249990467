/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

const  ReactGA = require('react-ga')
ReactGA.initialize('UA-93365504-13')
ReactGA.set({ anonymizeIp: true })

exports.onRouteUpdate = (state, page, pages) => {
  if (window.location.hostname.includes('frekvensapp')) {
    ReactGA.pageview(state.location.pathname)
  }
}
